import React, { useContext, useState } from "react";
import CancelButton from "../../components/Button/OutlinedButton";
import SubmitButton from "../../components/Button/Button";
import { ErrorMessage, Form, Formik } from "formik";
import PageHeader from "../../components/Title";
import ErrorDialog from "../../components/Dialog/ErrorDialog";
import SuccessDialog from "../../components/Dialog/SuccessDialog";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import DatePicker from "../../components/FormsUI/DatePicker";
import { templates } from "../../common/constants";
import TextFieldWrapper from "../../components/FormsUI/TextField";
import dayjs from "dayjs";
import ButtonWrapper from "../../components/Button/Button";
import UploadImage from "../../components/Files/UploadImage";
import InputLabel from "../../components/FormsUI/InputLabel";
import TextAreaWrapper from "../../components/FormsUI/TextArea";
import newIcon from "../../assets/img/new.png";
import MultiSelect from "../../components/Select/MultiSelect";
import { listCompanies } from "../../lib/dropDownList-api";
import { useQuery } from "react-query";

const AdminEventsCreate = () => {
  const styles = {
    divStyle: "grid grid-cols-1 md:grid-cols-2 gap-x-20 mt-0 md:mt-7 gap-y-2",
  };
  const boxStyle = {
    width: "100%",

    height: "120px",
  };

  const { t } = useTranslation();
  const { template } = useContext(AuthContext);
  const params = useParams();
  const action = params?.action;
  const eventId = params?.eventId;
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [companyListOption, setCompanyListOption] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState("");

  useQuery("/adminCompanyList", listCompanies, {
    onSuccess: (resp) => {
      console.log("Company data", resp);
      let optionData = resp?.data?.companyNames
        ?.filter((ele) => ele.kc_companyname !== null)
        .map((ele) => {
          const company = ele.kc_companyname ? ele.kc_companyname.split("~") : [];

          if (company.length === 2) {
            return {
              // label: `${company[0]} - ${company[1]}`,
              label: ele.display_name,
              value: company[1],
              id: ele.company_id,
              account_id: `${company[1]}`,
            };
          } else {
            // return { label: company[0], value: company[0], id: ele.company_id };
            return { label: ele.display_name, value: company[0], id: ele.company_id };
          }
        });
      setCompanyListOption(optionData);

      // console.log("data", companyListOption);
    },
  });

  return (
    <div className="py-10 px-5 sm:px-12 w-full">
      <div className="max-w-[1138px]">
        <div className="flex gap-5 flex-wrap">
          <PageHeader
            title={action !== "edit" ? t("_Edit_Event") : t("_Create_Event")}
          ></PageHeader>
        </div>
      </div>
      <Formik
        initialValues={{
          eventImage: "",
          eventTitle: "",
          eventDate: "",
          publishDate: "",
          eventDescription: "",
          companyName: [],
        }}
        validateOnMount={true}
        // validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          if (action !== "edit") {
          } else {
          }
        }}
      >
        {({ values, errors, handleChange, handleBlur, touched, setFieldValue }) => (
          <Form>
            <div className={styles.divStyle}>
              <div>
                <TextFieldWrapper
                  name="eventTitle"
                  label="_Event_Title"
                  isMandatory={true}
                ></TextFieldWrapper>
              </div>
              <div>
                <div>
                  <DatePicker
                    name="eventDate"
                    label={"_Event_date"}
                    isMandatory={true}
                    format={"DD MMM YYYY"}
                    minDate={dayjs(new Date())}
                    //   maxDate={dayjs(new Date()).add(30, "d")}
                  ></DatePicker>
                </div>
              </div>
            </div>
            <div className={styles.divStyle}>
              <div>
                <DatePicker
                  name="publishDate"
                  label={"_Publish_till"}
                  isMandatory={true}
                  format={"DD MMM YYYY"}
                  minDate={dayjs(new Date())}
                  //   maxDate={dayjs(new Date()).add(30, "d")}
                ></DatePicker>
              </div>
              <div className="relative mt-1">
                <InputLabel label={"_Upload_image"} isMandatory={true} />
                <UploadImage
                  setFieldValue={setFieldValue}
                  name={"eventImage"}
                  value={values.profilePicture}
                />

                <ErrorMessage
                  name={"eventImage"}
                  component="div"
                  className="text-[#d32f2f] absolute text-[11px] -mt-[11px]"
                ></ErrorMessage>
              </div>
            </div>
            <div className="mt-8">
              <TextAreaWrapper
                label={"_Event_description"}
                name="eventDescription"
                textAreaClassName={
                  "rounded-[5px] border disabled:border-2 disabled:bg-opacity-30 disabled:bg-[#BBBBBA]"
                }
                minRows={7}
                maxRows={12}
              ></TextAreaWrapper>
              <ButtonWrapper
                label={t("_Refine_description_using_Cubot")}
                buttonClassName="w-full md:w-[260px] h-[40px] text-xs mt-1 "
                // onClick={(e) => handleRefineClick(e, values, finalData)}
              >
                <img
                  src={newIcon}
                  alt="new feature"
                  height={30}
                  width={30}
                  className="inline-block mr-1"
                />
              </ButtonWrapper>
            </div>
            <div className={styles.divStyle}>
              <div>
                <InputLabel label="_Company_name" isMandatory={true} />
                <MultiSelect
                  name="companyName"
                  options={companyListOption}
                  isMulti={true}
                  placeholder={""}
                  isSearchable={true}
                  isClearable={false}
                  onChange={(selectedOption) => {
                    //console.log("selectedOption", selectedOption);
                    setFieldValue(
                      "companyName",
                      selectedOption ? selectedOption.value : ""
                    );
                    setSelectedAccountId(selectedOption ? selectedOption.value : "");
                  }}
                />
              </div>
              <div>
                <InputLabel label="_Jobs_associated_with_event" isMandatory={true} />
                <MultiSelect
                  name="associatedJobs"
                  options={companyListOption}
                  isMulti={true}
                  placeholder={""}
                  isSearchable={true}
                  isClearable={false}
                  onChange={(selectedOption) => {
                    //console.log("selectedOption", selectedOption);
                    setFieldValue(
                      "associatedJobs",
                      selectedOption ? selectedOption.value : ""
                    );
                  }}
                />
              </div>
            </div>
            <div className="mt-[40px] flex flex-wrap gap-5">
              <SubmitButton
                type="submit"
                label={action === "edit" ? "_Save" : "_SUBMIT"}
                // loading={isLoading}
                buttonClassName="w-full md:w-[145px] h-dropdownHeight text-xs"
              ></SubmitButton>
              <CancelButton
                type="button"
                label={t("_CANCEL")}
                buttonClassName="w-full md:w-[145px] h-dropdownHeight text-xs"
                onClick={() =>
                  navigate(
                    template === templates.platformAdmin
                      ? "/app/admin/events"
                      : "/app/recruiter/events"
                  )
                }
              ></CancelButton>
            </div>
          </Form>
        )}
      </Formik>
      <SuccessDialog
        message={message}
        open={successDialog}
        onOkayClick={() => {
          setSuccessDialog(false);
          navigate("/app/admin/assessments");
        }}
      ></SuccessDialog>
      <ErrorDialog
        message={errorMessage}
        open={errorDialog}
        onClick={() => setErrorDialog(false)}
      ></ErrorDialog>
    </div>
  );
};

export default AdminEventsCreate;
