import { useField, useFormikContext } from "formik";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import InputLabel from "../InputLabel";
const DatePickerFieldWrapper = ({
  name,
  label,
  maxDate,
  labelClassName,
  labelColor,
  isMandatory,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [selectedValue, setSelectedValue] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    setSelectedValue(dayjs(field.value));
  }, [field.value]);
  //console.log("date", dayjs(field.value));
  const configDateField = {
    ...field,
    ...rest,
    fullWidth: true,
    //type: "date",

    InputLabelProps: { shrink: true },
    //defaultValue: "24/05/2017",
  };

  if (meta && meta.touched && meta.error) {
    configDateField.error = true;
    configDateField.helperText = meta.error;
  }
  return (
    <div>
      {/* <div className="text-formLabelColor text-medium mb-2">{t(label)}</div> */}
      {label && (
        <InputLabel
          label={label}
          color={labelColor}
          labelClassName={labelClassName}
          isMandatory={isMandatory}
        />
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className="w-full"
          maxDate={maxDate}
          format={rest.format}
          minDate={rest.minDate}
          // maxDate={ dayjs(new Date())}
          value={selectedValue}
          onChange={(val) => {
            //console.log(val);
            setSelectedValue(val);
            setFieldValue(name, val);
            setFieldTouched(name, true);
          }}
          sx={{
            "& label.Mui-focused": {
              color: "#2D2D2D",
            },
            "& .MuiOutlinedInput-root": {
              height: "40px",
              fontSize: "12px",
              fontWeight: "500",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #70707059 !important",
                borderRadius: "5px",
              },
              "&:hover fieldset": {
                border: "2px solid #2D2D2D !important",
              },
              "&.Mui-focused fieldset": {
                border: "2px solid #2D2D2D !important",
              },
            },
          }}
        />
      </LocalizationProvider>
      {/* <CssTextField
        {...configDateField}
        value={selectedValue}
        max={new Date()}
        onChange={(event) => {
          setSelectedValue(event.target.value);
          setFieldValue(name, event.target.value);
        }}
      ></CssTextField> */}
      {meta.error ? (
        <p className="text-[11px] text-[#D32F2F] mt-0.5">{meta.error}</p>
      ) : (
        ""
      )}
    </div>
  );
};

export default DatePickerFieldWrapper;
