import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Upload from "../../assets/img/Upload.png";
import Tick from "../../assets/img/green-tick.png";
import Cross from "../../assets/img/black-tick.png";
import Box from "@mui/material/Box";
import { PdfIcon } from "../../icons";
import { useTranslation } from "react-i18next";
import { UploadResumeIcon, CloseIcon } from "../../icons";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import LinearProgress from "@mui/material/LinearProgress";
import { Button, Typography } from "@mui/material";

function UploadFile({
  setCondition,
  setFieldValue,
  name,
  value,
  isSubscriptionPage,
  isHeaderResume,
  isJsonFormat,
  boxStyle,
  isLoading,
  firstConditionLabel,
  secondConsitionLabel,
  handleDisableNext,
  content,
  uploadProgress,
  isExcel,
  // handleFileUpload,
  enableCustomDeleteFn = false,
  customDeleteFunction,
}) {
  console.log("upload file check krr lo", uploadProgress);
  const { t } = useTranslation();

  const [isLessThan5MB, setIsLessThan5MB] = useState(false);
  const [isPDFNDocFormat, setIsPDFNDocFormat] = useState(false);
  const [isJSONFormat, setIsJSONFormat] = useState(false);
  const [isExcelFormat, setIsExcelFormat] = useState(false);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: isExcel
      ? {
          "text/csv": [".csv"],
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        }
      : isJsonFormat
      ? { "application/json": [".json"] }
      : {
          "application/pdf": [".pdf"],

          "application/msword": [".doc"],

          "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
            ".docx",
          ],
          // "text/csv": [".csv"],
          // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xls"],
          // "application/vnd.ms-excel": [".xlsx"],
        },
    maxSize: 100 * 1024 * 1024,
    maxFiles: 1,
    multiple: false,
    noClick: true,

    onDropAccepted: (files) => {
      const file = files[0];
      setFieldValue(name, file);
      setIsLessThan5MB(file.size < 5 * 1024 * 1024);
      setIsPDFNDocFormat(
        file.type === "application/pdf" ||
          file.type === "application/msword" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type === "text/csv" ||
          file.type === "application/vnd.ms-excel" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      setIsJSONFormat(file.type === "application/json");
      setIsExcelFormat(
        file.type === "text/csv" ||
          file.type === "application/vnd.ms-excel" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      const formData = new FormData();
      formData.append("file", file);
      handleDisableNext(false);
      // handleFileUpload(formData);
    },
    onDropRejected: () => {
      setIsLessThan5MB(false);
      setIsPDFNDocFormat(false);
      setIsJSONFormat(false);
      setIsExcelFormat(false);
    },
  });

  useEffect(() => {
    const condition =
      (isLessThan5MB && isPDFNDocFormat) ||
      (isLessThan5MB && isJSONFormat) ||
      (isLessThan5MB && isExcelFormat) ||
      value?.name;
    setCondition(condition);
  }, [isLessThan5MB, isPDFNDocFormat, isJSONFormat, setCondition, value?.name]);

  const renderIcon = (isValid, content) => {
    if (isValid) {
      return (
        <div className="flex items-center mr-3">
          <img className="w-3 h-3 mr-1" src={Tick} alt="Tick" />
          <p className="text-xs text-grayTextColor whitespace-no-wrap">{t(content)}</p>
        </div>
      );
    } else {
      return (
        <div className="flex items-center mr-3">
          <img className="w-3 h-3 mr-1" src={Cross} alt="Cross" />
          <p className="text-xs text-grayTextColor whitespace-no-wrap">{t(content)}</p>
        </div>
      );
    }
  };

  const removeFile = (e) => {
    e.stopPropagation();
    setFieldValue(name, "");
    setIsLessThan5MB(false);
    setIsPDFNDocFormat(false);
    setIsJSONFormat(false);
    setIsExcelFormat(false);
    if (handleDisableNext) {
      handleDisableNext(true);
    }
  };

  const deleteFileFunction = (e) => {
    removeFile(e);
    customDeleteFunction();
  };

  return (
    <Box
      width={"100%"}
      onClick={() => {
        if (value === "") {
          open();
        }
      }}
    >
      {isSubscriptionPage ? (
        <>
          {value === "" ? (
            <Button
              className="px-8 mb-2 h-[50px] border-borderInactive rounded-5px border mt-5 md:mt-0 normal-case hover:bg-transparent w-full"
              variant="outlined"
            >
              <UploadResumeIcon className="inline"></UploadResumeIcon>
              <span className="text-xs text-titleSecondary my-auto ml-10px">
                {t("_Upload_contract")}
              </span>
            </Button>
          ) : value === "" || value.name === "" ? (
            <div className="mb-2">
              <input {...getInputProps()} />
              <img className="w-8 h-7" src={Upload} alt="Upload" />
            </div>
          ) : (
            <div>
              <div className="mb-2 flex justify-between w-[330px] flex-1 max-w-xs h-[50px] border px-4 border-borderTertiary bg-bgSecondary items-center">
                <div className="flex gap-5">
                  <PdfIcon />
                  <div className="sm:w-[187px]">
                    <p className="font-semibold truncate  text-medium whitespace-pre-wrap mt-2">
                      {value?.name}
                    </p>
                  </div>
                </div>
                <button
                  onClick={removeFile}
                  className="text-[#FF686E] text-xs font-semibold m-0 p-0"
                  type="button"
                >
                  {t("_Remove")}
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          {...getRootProps({ className: "dropzone " })}
          className={`mb-2 bg-transparent relative flex items-center justify-center cursor-pointer rounded-[5px] border border-borderPrimary`}
          style={{
            width: boxStyle.width,
            height: boxStyle.height,
            border: boxStyle.border,
            backgroundColor: boxStyle.backgroundColor,
          }}
        >
          {isLoading ? (
            <Box sx={{ width: "80%" }}>
              <LinearProgress variant="indeterminate" />
              <p className="text-medium font-medium text-inputColor my-1">
                {t("_Uploading_file")}
              </p>
            </Box>
          ) : value === "" || value?.name === "" ? (
            <div className="flex flex-col items-center">
              <input {...getInputProps()} />
              <img className="w-8 h-7" src={Upload} alt="Upload" onClick={open} />
              {content && (
                <span className="mt-5 text-grayTextColor text-sm font-semibold">
                  {t(content)}
                </span>
              )}
            </div>
          ) : (
            <div>
              <div className="flex justify-between w-[330px] flex-1 max-w-xs h-[60px] border px-4 border-borderTertiary bg-bgSecondary items-center">
                <div className="flex gap-5">
                  <PdfIcon />
                  <div className="sm:w-[187px]">
                    <p className="font-semibold truncate  text-xs whitespace-pre-wrap mt-2">
                      {value?.name ? value.name : value ? value : ""}
                      {/* {value?.name} */}
                    </p>
                  </div>
                </div>
                <button
                  // onClick={removeFile}
                  onClick={enableCustomDeleteFn ? deleteFileFunction : removeFile}
                  className="text-[#FF686E] text-xs font-semibold m-0 p-0"
                  type="button"
                >
                  {t("_Remove")}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <div className=" flex flex-wrap my-3 text-grayTextColor text-xs">
        {renderIcon(
          isLessThan5MB,
          firstConditionLabel ? firstConditionLabel : "_Less_than_5_MB"
        )}
        {isJsonFormat
          ? renderIcon(
              isJSONFormat,
              secondConsitionLabel ? secondConsitionLabel : "_Must_be_in_JSON_format"
            )
          : isExcel
          ? renderIcon(
              isPDFNDocFormat,
              secondConsitionLabel
                ? secondConsitionLabel
                : "_Must_be_in_XLSX_or_XLS_or_CSV_format"
            )
          : renderIcon(
              isPDFNDocFormat,
              secondConsitionLabel
                ? secondConsitionLabel
                : "_Must_be_in_PDF_or_Doc_or_DocX_format"
            )}
      </div>
    </Box>
  );
}

export default UploadFile;
